<template>
  <div>
    <el-skeleton :rows="row" animated />
  </div>
</template>

<script>
export default {
  name: "Skeleton-List",
  props: {
    row: {
      type: Number,
      default: 5,
    },
  },
};
</script>

<style lang="scss" scoped></style>
